import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SHOW_TOAST]: (state, toast) => {
    const { color, timeout, message } = toast

    state.isToastVisible = true
    state.toast = {
      message,
      color,
      timeout,
      show: true,
    }
  },
  [mutationTypes.HIDE_TOAST]: (state) => {
    state.isToastVisible = false
  },
}
