import mutationTypes from './mutation-types'
import { getAccounts, updateAccount } from '@/api/admin/accounts'

export default {
  async loadAccountInfo({ commit, dispatch }) {
    try {
      const response = await getAccounts()
      const account = response?.[0]

      commit(mutationTypes.SET_ACCOUNT_INFO, account)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:loadAccountInfo', error)
    }
  },
  async updateAccountInfo({ commit, dispatch }, { id, payload }) {
    try {
      const response = await updateAccount(id, payload)
      const account = response?.[0]

      commit(mutationTypes.SET_ACCOUNT_INFO, account)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:loadAccountInfo', error)
    }
  },
}
