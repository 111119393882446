import mutationTypes from './mutation-types'

export default {
  hideToast: ({ commit }) => {
    commit(mutationTypes.HIDE_TOAST)
  },
  setCurrentError: ({ state, commit }, { message = 'Failed!' }) => {
    if (state.isToastVisible) commit(mutationTypes.HIDE_TOAST)

    setTimeout(() => {
      commit(mutationTypes.SHOW_TOAST, {
        color: 'error',
        message,
        timeout: 4000,
      })
    })
  },
}
